<template>
  <div class="container" v-loading="loading"
       element-loading-text="正在导出中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="page-title" style="padding: 0 20px;background-color: rgb(235,97,0);justify-content: space-between;">
      <div>
        <i class="el-icon-back" @click="back"  style="cursor:pointer"></i>
        <span class="el-icon-search" style="margin-left: 7px;">在线选图系统</span>
      </div>
      <div style="display: flex;">
        <el-tooltip class="item" effect="dark" content="点击复制" placement="right">
          <div @click="share">生成分享链接</div>
        </el-tooltip>
        <el-button icon="el-icon-download" style="margin-left: 10px;" @click="orderInfoExport" type="primary" size="mini">导出</el-button>
      </div>
    </div>
    <div class="content">
      <div class="content-bottom">
        <div class="content-box">
          <div class="food-content" v-for="(value,key) in tableData" :key="'key'+key">
            <div class="secondName">
              <span>{{value.secondName}}</span>
              <div class="button-box">
                <el-button icon="el-icon-plus" @click="dialogAddSecond = true" type="primary" size="mini">加类</el-button>
                <el-button icon="el-icon-delete" @click="deleteSecond(value.id)" type="danger" size="mini">删类</el-button>
                <el-button icon="el-icon-plus" @click="dialoAddFoodName(value.id)" type="primary" size="mini">加菜</el-button>
              </div>
            </div>
            <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;">
              <div class="food-box" v-for="(item,index) in value.orderInfoLastList" :key="index">
                <div style="width:100%;height: 200px; border: 3px solid #999;box-sizing: border-box;">
                  <img alt="" v-if="item.imgSmallUrl" class="food-image" :src="baseURL + '/images'+item.imgSmallUrl">
                </div>
                <div class="image-size">
                  <div>
                    <span class="image-label el-icon-remove" @click="confirm('free',item)">免图</span>
                    <span class="image-label el-icon-right" @click="changImage(value,item)">换图</span>
                  </div>
                </div>
                <div class="image-desc">
                  <div class="image-info">
                    <div class="info-item">
                      {{item.lastName || '暂无'}}
                    </div>
                    <i class="el-icon-download" @click="downloadIamge(item.imgUrl)"></i>
                    <i class="el-icon-delete" @click="deleteFoodName(item.id)"></i>
                  </div>
                </div>
              </div>
              <div style="font-size: 14px;color: #909399;margin: 10px auto 0;" v-if="!(value.orderInfoLastList && value.orderInfoLastList.length > 0)">暂无数据</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="在线选图" :close-on-click-modal="false" :visible.sync="dialogTableVisible" center :append-to-body='true' :lock-scroll="false" width="50%">
      <div class="">
        <div class="first-line">
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 50px;text-align: right;">名称</div>
            <el-input
              placeholder="请输入内容"
              v-model="lastName"
              clearable>
            </el-input>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          </div>
        </div>
        <div class="dialog-image-box" style="width: 100%;display: flex;justify-content: center;">
          <div class="dialog-image-item" v-for="(item,index) in imgDataList" :key="index">
            <el-image style="width: 150px;height: 150px;"
              :src="baseURL + '/images'+item.imgSmallUrl"
              :preview-src-list="imageSrcList"
            >
            </el-image>
            <el-radio class="image-radio" v-model="imageRadioId" :label="index"></el-radio>
            <div class="desc">{{item.lastName}}</div>
          </div>
          <div style="text-align: center;font-size: 14px;color: #909399;margin-top: 10px;" v-if="!(imgDataList && imgDataList.length > 0)">暂无数据</div>
        </div>
        <div style="display: flex;justify-content: center;margin-bottom: 10px;">
          <el-button :disabled="!imageRadioId && imageRadioId !== 0" width="80px" type="primary" size="mid" style="margin-top: 30px;" @click="confirm">确定</el-button>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[5, 10, 20, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="total"
        ></el-pagination>
      </div>
    </el-dialog>
<!--    加类-->
    <el-dialog title="加类" :close-on-click-modal="false" :visible.sync="dialogAddSecond" center :append-to-body='true' :lock-scroll="false" width="60%">
      <div class="">
        <div class="first-line">
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 50px;text-align: right;">大类</div>
            <el-select filterable @change="changeBigType(firstNameForAddSecond)" v-model="firstNameForAddSecond" placeholder="请选择" style="width: 132px;">
              <el-option
                v-for="item in bigTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 50px;text-align: right;">小类</div>
            <el-select filterable v-model="secondNameForAddSecond" placeholder="请选择" style="width: 132px;">
              <el-option
                v-for="item in smallTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="display: flex;justify-content: center;margin-bottom: 10px;">
          <el-button :disabled="!(secondNameForAddSecond && firstNameForAddSecond)" width="80px" type="primary" size="mid" style="margin-top: 30px;" @click="addSecond">确定</el-button>
        </div>
      </div>
    </el-dialog>
<!--  加菜-->
    <el-dialog title="加菜" :close-on-click-modal="false" :visible.sync="dialogAddFoodName" center :append-to-body='true' :lock-scroll="false" width="60%">
      <div class="">
        <div class="first-line">
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 50px;text-align: right;">大类</div>
            <el-select filterable @change="changeBigType(firstNameForFoodName)" v-model="firstNameForFoodName" placeholder="请选择" style="width: 132px;">
              <el-option
                v-for="item in bigTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 50px;text-align: right;">小类</div>
            <el-select filterable @change="changeSmallType(secondNameForFoodName)" v-model="secondNameForFoodName" placeholder="请选择" style="width: 132px;">
              <el-option
                v-for="item in smallTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 50px;text-align: right;">名称</div>
            <el-select filterable v-model="lastNameForFoodName" placeholder="请选择" style="width: 132px;">
              <el-option
                v-for="item in foodNames"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="display: flex;justify-content: center;margin-bottom: 10px;">
          <el-button :disabled="!(firstNameForFoodName && secondNameForFoodName && lastNameForFoodName)" width="80px" type="primary" size="mid" style="margin-top: 30px;" @click="addFoodName">确定</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
  export default {
    data() {
      return {
        tableData: [],
        imgDataList: [],
        imageRadioId: '',
        changeItem:{},
        value: '',
        total: 0,
        pageSize: 10,
        currentPage:1,
        listLoading: false,
        type: '',
        id: '',
        dialogTableVisible:false,  //添加弹框
        queryId: '',  //上个界面
        findPictureData: {},
        bigTypes: [],  //大类
        smallTypes: [],
        foodNames: [],
        firstName:'',
        secondName:'',
        lastName:'',
        dialogAddSecond: false,
        firstNameForAddSecond:'',
        secondNameForAddSecond:'',
        dialogAddFoodName: false,
        firstNameForFoodName:'',
        secondNameForFoodName:'',
        lastNameForFoodName:'',
        addFoodNameId: '',
        orderKey: '',
        timer: null,
        loading: false,
        baseURL: ''
      };
    },
    activated () {
      this.baseURL = window.baseURL;
      this.queryId = this.$route.query.id;
      this.orderKey = this.$route.query.orderKey;
      this.findOrderInfo();
      this.queryBigTypes(); //查询大类
    },
    methods: {
      findOrderInfo(type){
        this.$http.post("/order/findOrderInfo", {id: this.queryId}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data);
              const data = res.data.data || {}
              this.tableData = data.orderInfoSecondList;
              if(type === 'down' &&  data.orderInfo && data.orderInfo.downUrl){
                const url = window.baseURL + '/images'+ data.orderInfo.downUrl;
                window.open(url)
              }
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      queryBigTypes(){
        this.$http.post(`/menuName/findFirstName`, {}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====queryOptions',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.bigTypes = res.data.data;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      changeBigType(id){
        console.log('changeBigType=======')
        const data = {
          firstId: id
        }
        this.$http.post("/menuName/findSecondName", data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.smallTypes = res.data.data;
              this.secondName = ''
              this.lastName = '';
              this.secondNameForAddSecond = ''
              this.secondNameForFoodName = ''
              this.lastNameForFoodName = ''
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      changeSmallType(id){
        console.log('changeSmallType=======')
        const data = {
          secondId: id
        }
        this.$http.post("/menuName/findLastName", data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.foodNames = res.data.data;
              this.lastName = '';
              this.lastNameForFoodName = ''
              // this.foodNames = res.data.data;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      search(){
        this.imageRadioId = ''
        this.currentPage = 1;
        const data = {
          lastName: this.lastName
        };
        this.findPictureData = data;
        this.findPicture(this.currentPage,this.pageSize);
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.findPicture(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.findPicture(this.currentPage,this.pageSize);
      },
      downloadIamge(imgsrc, name) {//下载图片地址和图片名
        var image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function() {
          var canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          var context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

          var a = document.createElement("a"); // 生成一个a元素
          var event = new MouseEvent("click"); // 创建一个单击事件
          a.download = name || "photo"; // 设置图片名称
          a.href = url; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = window.baseURL + '/images' + imgsrc;
      },
      back(){
        this.$router.go(-1);
      },
      changImage(value,item){
        console.log('item===',value,item);
        this.changeItem = item;
        const data = {
          firstId: item.firstId,
          secondId: value.secondId,
          lastId: item.lastId,
          lastName: item.lastName
        };
        this.findPictureData = data;
        this.findPicture(this.currentPage,this.pageSize);
      },
      findPicture(currentPage,pageSize){
        this.$http.post(`/order/findPicture/${currentPage}/${pageSize}`, this.findPictureData , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.imgDataList = res.data.data.list;
              this.total = res.data.data.total;
              this.imageRadioId = ''
              this.dialogTableVisible = true;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      confirm(type,item){
        console.log('type===',type)
        item && (this.changeItem = item);
        // console.log("+++++++++",  this.imgDataList[this.imageRadioId].imgId,this.changeItem.id);
        // const imgId = this.imgDataList[this.imageRadioId].imgId;
        let data = {
          id:this.changeItem.id
        };
        type !== 'free' && (data.imgId = this.imgDataList[this.imageRadioId].imgId);
        console.log('data',data)
        this.$http.post(`/order/choosePicture`, data , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              const text = type === 'free' ? '免图成功' :'换图成功'
              this.$message.success(text)
              this.dialogTableVisible = false;
              this.findOrderInfo();
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      share(){
        const url = location.href.replace(location.hash,'')+"#/share-image?orderKey="+String(this.orderKey);
        let _this = this;
        this.$copyText(url).then(function (e) {
          _this.$message({
            showClose: true,
            message: "复制成功",
            type: "success"
          });
        }, function (e) {
          _this.$message({
            showClose: true,
            message: "复制失败",
            type: "error"
          });
        })
      },
      addSecond(){
        const value = this.smallTypes.find((item,index)=>{
           return item.id === this.secondNameForAddSecond
        })
        const data = {
          orderId:this.queryId,
          secondId:this.secondNameForAddSecond,
          secondName: value.name
        }
        console.log('data',data)
        this.$http.post(`/order/saveSecondType`, data , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.dialogAddSecond = false
              this.findOrderInfo();
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      dialoAddFoodName(id){
        this.dialogAddFoodName = true;
        this.addFoodNameId = id;
      },
      addFoodName(){
        const value = this.smallTypes.find((item,index)=>{
          return item.id === this.secondNameForFoodName
        })
        const lastValue = this.foodNames.find((item,index)=>{
          return item.id === this.lastNameForFoodName
        })
        const data = {
          infoSecondId: this.addFoodNameId,
          lastId: this.lastNameForFoodName,
          lastName: lastValue.name
        }
        console.log('data',data)
        this.$http.post(`/order/saveLastType`, data , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.dialogAddFoodName = false
              this.findOrderInfo();
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      deleteSecond(id){
        this.$http.post(`/order/deleteSecondType`, { id } , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.findOrderInfo();
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      deleteFoodName(id){
        this.$http.post(`/order/deleteLastType`, { id } , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.findOrderInfo();
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      orderInfoExport(){
        this.$http.post(`/order/orderInfoExport`, { id: this.queryId } , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.timer = setInterval(()=>{
                this.findResult(res.data.data.id)
              },3000)
            } else {
              this.$message.error(res.data.data)
            }
          });

        this.loading = true;
        this.timer = setInterval(()=>{
          this.exportSource();
        },3000)
      },
      findResult(id){
        this.$http.post("/order/findResult", {id}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              if(res.data.data.status === 2){
                clearInterval(this.timer);
                this.loading = false;
                // this.orderInfo = '';
                this.findOrderInfo('down');
              }
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      exportSource(){

      }
    },
    watch: {
      dialogTableVisible(newVal){
        if(!newVal){
          this.imageRadioId = '';
          this.currentPage = 1;
          this.firstName = '';
          this.secondName = '';
          this.lastName = '';
        }
      },
      dialogAddSecond(newVal){
        if(!newVal){
         this.firstNameForAddSecond = '';
         this.secondNameForAddSecond = '';
        }
      },
      dialogAddFoodName(newVal){
        if(!newVal){
          this.firstNameForFoodName = '';
          this.secondNameForFoodName = '';
          this.lastNameForFoodName = '';
        }
      },
    },
    filters: {

    },
    computed: {
      imageSrcList() {
        let data = [];
        this.imgDataList.forEach(item => {
          data.push(this.baseURL + '/images'+item.imgUrl);
        })
        return data;
      }
    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
    /*margin-bottom: 20px;*/
    overflow: auto;
    .content-top {
      display: flex;
      margin-top: 10px;
      justify-content: flex-start;
      flex-direction: column;
    }
    .content-bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
  .first-line {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*text-align: right;*/
    font-size: 16px;
    color: #000000;
    padding-right: 10px;
  }
  .date-box {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  .third-line {
    margin-left: 20px;
    margin-top: 10px;
  }
  .content-box {
    display: flex;
    flex-direction: column;
  }
  .food-content {
    /*flex:1;*/
    display: flex;
    flex-direction: column;
    /*flex-wrap: wrap;*/
    /*justify-content: flex-start;*/
    margin: 10px;
    margin-bottom: 0;
    font-size: 12px;
    overflow: auto;
    .secondName {
      background-color: rgb(111,161,212);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      height: 38px;
      font-size: 16px;
      position: relative;
      .button-box {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .food-box {
    display: flex;
    flex-direction: column;
    width: 230px;
    margin: 0 40px 10px 0;
    position: relative;
    /deep/ .el-checkbox {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    /deep/ .el-checkbox__label {
      display: none;
    }
  }
  .food-image {
    width: 100%;
    height: 200px;
    object-fit: contain;;//转化不同类型
    /*padding: 3px;*/
    /*background-color: #999;*/
    /*box-sizing: border-box;*/
  }
  .image-size {
    display: flex;
    background-color: #999;
    padding: 3px 0 4px;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      .image-label {
        display: flex;
        background-color: #ccc;
        width: 40px;
        padding: 3px 10px;
        border-radius: 20px;
        background-color: rgb(225,229,238);
        &:first-child {
          margin-right: 10px;
        }
      }
      .image-value {
        display: flex;
        flex: 1;
        background-color: #fff;
        padding: 3px;
        border-radius: 0 10px 10px 0;
      }
    }
  }
  .image-desc {
    display: flex;
    flex-direction: column;
    .big-type {
      font-size: 14px;
      text-align: left;
      padding: 3px 0 3px 10px;
      border: 3px solid #ccc;
    }
    .image-info {
      font-size: 14px;
      border: 3px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ccc;
      .info-item{
        flex: 1;
        /*display: flex;*/
        background-color: #fff;
        margin-right: 5px;
      }
    }
  }
  /deep/ .cell {
    text-align: center;
  }
  /deep/ .el-tooltip.copy  {
    margin-right: 0!important;
    width: auto!important;
  }
  .dialog-image-box {
    display: flex;
    flex-wrap: wrap;
  }
  .dialog-image-item {
    /*width: 150px;*/
    /*height: 150px;*/
    padding: 5px;
    position: relative;
    /deep/ img {
      width: 100%;
      height: 100%;
      object-fit: contain;;//转化不同类型
    }
    .image-radio {
      position: absolute;
      top: 10px;
      left: 10px;
      /deep/ .el-radio__label {
        display: none;
      }
    }
    .desc {
      text-align: center;
    }
  }
</style>
